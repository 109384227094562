<template>
  <div class="check">
    <div class="title">
      <div class="title_left">
        <div class="flex">
          <div class="flexBox" />
          <div class="flexBox" style="margin-right: 20px">
            <div class="flexTitle" style="width: 110px">接收时间</div>
            <el-select
              v-model="selectTime"
              style="width: 140px"
              placeholder="所有"
              clearable
              @change="timeChange"
            >
              <el-option key="0" label="15分钟" :value="1" />
              <el-option key="1" label="1小时" :value="2" />
              <el-option key="2" label="6小时" :value="3" />
              <el-option key="3" label="1天" :value="4" />
              <el-option key="4" label="7天" :value="5" />
            </el-select>
            <el-date-picker
              v-model="search_param.receipt_time"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="title_right">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: auto"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 250px)"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange1"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column type="index" label="序号" width="55" />
      <el-table-column label="报文编号" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.receipt_business }}</div>
        </template>
      </el-table-column>
      <el-table-column label="接收时间" min-width="110px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.receipt_time || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 20px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>回执通路</span>
          </span>
        </div>
        <el-divider />
        <div style="margin-bottom: 30px; padding: 0 30px">
          <div style="margin-bottom: 20px">
            <span style="margin-right: 120px">
              <span style="font-weight: 700; margin-right: 10px">报文编号</span>
              <span>{{ rowDetail.receipt_business }}</span>
            </span>
            <span style="margin-right: 120px">
              <span style="font-weight: 700; margin-right: 10px">接收时间</span>
              <span>{{ rowDetail.receipt_time }}</span>
            </span>
          </div>
          <div
            style="
              margin-bottom: 15px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span>详情:</span>
            <el-button type="primary" @click="copyButton">复制</el-button>
          </div>
          <div>
            <el-input
              v-model="rowDetail.receipt_content"
              :rows="20"
              readonly
              type="textarea"
            />
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { list_receipt, receipt_detail } from "@/api/bank/app.js";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import useClipboard from "vue-clipboard3";

//查询参数对象
const search_param = reactive({
  receipt_time: [],
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  search_param.receipt_time = [];
  selectTime.value = "";
};

const showDrawer = ref(false);
const selectId = ref("");
const rowClick = async (item) => {
  selectId.value = item.receipt_id;
  await getDetail();
  showDrawer.value = true;
};

const rowDetail = ref({});
// 获取详情
const getDetail = async () => {
  let res = await receipt_detail({ receipt_id: selectId.value });
  if (res.code == 200) {
    rowDetail.value = res.data.info;
  }
};

const selectTime = ref("");
const timeChange = () => {
  const start = new Date();
  const end = new Date();
  if (selectTime.value == 1) {
    start.setTime(start.getTime() - 3600 * 1000 * 0.25);
  }
  if (selectTime.value == 2) {
    start.setTime(start.getTime() - 3600 * 1000 * 1);
  }
  if (selectTime.value == 3) {
    start.setTime(start.getTime() - 3600 * 1000 * 6);
  }
  if (selectTime.value == 4) {
    start.setTime(start.getTime() - 3600 * 1000 * 24);
  }
  if (selectTime.value == 5) {
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
  }
  search_param.receipt_time = [start, end];
};

// 复制功能
const { toClipboard } = useClipboard();
const copyButton = async () => {
  try {
    await toClipboard(rowDetail.value.receipt_content);
    ElMessage({
      grouping: true,
      showClose: true,
      message: "复制成功",
      type: "success",
    });
  } catch (e) {
    console.error(e);
  }
};
//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};
//获得数据列表
const getList = ({ cP = 1, size = 10, tips = false }) => {
  currentPage.value = cP;
  pageSize.value = size;
  list_receipt({
    receipt_time: search_param.receipt_time || [],
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      totalCount.value = res.data.count;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
  });
};

const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.title {
  display: flex;
}
.title_left {
  flex: 1;
}
.title_right {
  float: right;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
